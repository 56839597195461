.footer {
  position: relative;
  border-top: 1px solid #D8D8D8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 40px 18px 32px;
  background-color: #F0F0F0;
}

.logo_wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.logo_mobile {
  display: none;
}

.logo_text {
  font-size: 22px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: -0.22px;
  text-align: center;
  color: #666666;
}

.description_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description {
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.16px;
  text-align: center;
  color: #666666;
}

@media screen and (max-width: 1024px) {
  .footer {
    flex-direction: column;
    gap: 8px;
    padding: 40px 64px 48px 60px;
  }

  .logo_desktop {
    display: none;
  }

  .logo_mobile {
    display: block;
  }

  .logo_text {
    color: #999;
  }

  .title {
    color: #999;
  }

  .description_wrapper {
    position: relative;
    width: fit-content;
    height: fit-content;
  }

  .description {
    font-weight: 400;
    line-height: 28px;
    color: #999;
  }
}
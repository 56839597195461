.service {
  width: 100%;
  padding: 0px 84px 0px 84px;
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--Meta-Default-Neutral-000, #FFF);
  box-shadow: 0px -2px 0px 0px #E8E8E8;
}

.splitter {
  width: 100%;
  height: 40px;
  /* background: linear-gradient(0deg, rgba(216, 216, 216, 0.00) -29998.36%, #727272 33848.15%);
  box-shadow: 0px -2px 0px 0px #E8E8E8; */
  /* background: var(--Meta-Default-Neutral-000, #FFF);
  box-shadow: 0px -2px 0px 0px #E8E8E8; */
  /* margin-bottom: 40px; */
}

.image_desktop {
  margin-bottom: 14px;
}

.image_mobile {
  display: none;
}

.image_description {
  font-size: 20px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0.2px;
  text-align: center;
  color: #999999;
}

.title {
  max-width: 640px;
  width: 100%;
  font-size: 32px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0.32px;
  text-align: center;
  color: #666666;
  margin-bottom: 16px;
}

.description {
  max-width: 640px;
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.2px;
  text-align: center;
  color: #999999;
}

@media screen and (max-width: 1368px){
  .service {
    padding: 0px;
  }
}

@media screen and (max-width: 640px){
  .splitter {
    height: 16px;
    /* background-color: #E8E8E8; */
    /* margin-bottom: 16px; */
  }

  .image_desktop {
    display: none;
  }

  .image_mobile {
    display: block;
    width: auto;
    height: 340px;
  }

  .image_description {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  .title {
    padding: 0 24px 0 24px;
    box-sizing: border-box;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.24px;
  }

  .description {
    padding: 0 24px 0 24px;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.16px;
  }
}
.header {
  z-index: 50;
  position: fixed;
  top: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 1000px;
  border: 1px solid var(--Meta-Default-Neutral-400, #D8D8D8);
  background: radial-gradient(802.64% 478.25% at 16.69% 317.36%, rgba(255, 255, 255, 0.48) 0%, rgba(153, 153, 153, 0.48) 100%);
  backdrop-filter: blur(62px);
  color: #666666;
  cursor: pointer;
}

.logo_wrapper {
  display: flex;
  align-items: center;
  padding: 0px 32px 0px 24px;
  gap: 12px;
}

.logo_text {
  font-size: 22px;
  line-height: 40px;
  font-weight: 500;
  letter-spacing: -0.22px;
  text-align: left;
}

.splitter_vertical {
  height: 100%;
  width: 1px;
  margin: 0px 22px 0px 0px;
  background-color: #D8D8D8;
}

.menu {
  display: flex;
  margin: 0;
  padding: 0;
}

.menu_link {
  text-decoration: none;
  color: #666666;
}

.menu_item {
  list-style-type: none;
  padding: 8px 24px 8px 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.16px;
  text-align: center;
}

.menu_item:hover {
  color: #777777;
  border-radius: 100px;
}

.menu_cta {
  background-color: #F0F0F0;
  border-radius: 100px;
}

@media screen and (max-width: 640px) {
  .header {
    top: 24px;
    padding: 0px 24px 0px 24px;
    box-sizing: border-box;
  }

  .wrapper {
    width: 100%;
    padding: 4px;
    justify-content: space-between;
  }

  .logo_wrapper {
    padding: 0px 0px 0px 20px;
  }

  .logo_text {
    display: none;
  }

  .splitter_vertical {
    display: none;
  }

  .menu_item {
    font-size: 14px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0.14px;
    padding: 3px 16px 3px 16px;
  }
}
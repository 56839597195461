.hero {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.image_wrapper {
  width: 100%;
  height: 554px;
  overflow: hidden;
  position: relative;
}

.image {
  width: 100%;
  position: absolute;
  left: 0;
  top: -883px;
}

.gradient_layer_left {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 18.48%, #FFFFFF 100%);
}

.gradient_layer_right {
  position: absolute;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 18.48%, #FFFFFF 100%);
}

.gradient_layer_full {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 8.12%, #FFFFFF 82.85%);
}

.content_wrapper {
  z-index: 10;
  margin-top: -65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.title {
  max-width: 1368px;
  font-weight: 400;
  font-size: 72px;
  line-height: 80px;
  letter-spacing: -0.72px;
  text-align: center;
  background: radial-gradient(395.86% 242.68% at 75.02% 254.55%, #A8A8A8 0%, #666 100%);
  background-clip: text;
  color: transparent;
}

.title_mobile {
  display: none;
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.32px;
  text-align: center;
  background: radial-gradient(52.48% 330.16% at 75.02% 254.55%, #A8A8A8 0%, #666 100%);
  background-clip: text;
  background-clip: text;
  color: transparent;
}

.description {
  max-width: 1024px;
  font-size: 28px;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: 0.28px;
  text-align: center;
  color: #999999;
}

/* .cta_link {
  text-decoration: none;
}

.cta_wrapper {
  border: 1px solid #D8D8D8;
  border-radius: 1000px;
  padding: 8px;
  cursor: pointer;
}

.cta_button {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 56px;
  letter-spacing: 0.01em;
  color: #666666;
  background-color: #F0F0F0;
  border: none;
  border-radius: 1000px;
  padding: 8px 24px 8px 24px;
}

.cta_button:hover {
  color: #777777;
} */

@media screen and (max-width: 1440px) {
  .image_wrapper {
    height: 480px;
  }

  .image {
    top: -600px;
  }

  .title {
    max-width: 968px;
    font-size: 68px;
    line-height: 76px;
  }

  .description {
    max-width: 968px;
  }
}

@media screen and (max-width: 1024px){
  .image_wrapper {
    height: 360px;
  }

  .image {
    top: -400px;
  }

  .title {
    max-width: 712px;
    font-size: 56px;
    line-height: 64px;
  }
  
  .description {
    max-width: 712px;
    font-size: 24px;
    line-height: 40px;
  }

  /* .cta_button {
    font-size: 20px;
    line-height: 48px;
  } */
}

@media screen and (max-width: 768px){
  .image_wrapper {
    height: 280px;
  }

  .content_wrapper {
    margin-top: -48px;
    gap: 24px;
  }

  .title {
    max-width: 600px;
    font-size: 44px;
    line-height: 56px;
  }
  
  .description {
    max-width: 480px;
    font-size: 20px;
    line-height: 36px;
  }

  /* .cta_button {
    font-size: 18px;
    line-height: 40px;
  } */
}

@media screen and (max-width: 640px){
  .hero {
    margin-bottom: 40px;
  }

  .image_wrapper {
    height: 269px;
  }

  .image {
    left: -74px;
    top: -169px;
    width: 137.34%;
  }

  .content_wrapper {
    margin-top: -28px;
    padding: 0 24px 0px 24px;
    gap: 16px;
  }

  .title {
    display: none;
  }

  .title_mobile {
    display: block;
  }
  
  .description {
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.16px;
  }
  
  /* .cta_wrapper {
    display: none;
  } */
}
.services {
  display: flex;
  flex-direction: column;
  gap: 96px;
  margin-bottom: 96px;
}

@media screen and (max-width: 640px) {
  .services {
    gap: 40px;
    margin-bottom: 42px;
  }
}
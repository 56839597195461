.features {
  width: 100%;
  padding: 0px 84px 0px 84px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.logo_wrapper {
  position: relative;
  width: 100%;
  padding: 111px 0 111px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: radial-gradient(95.8% 95.8% at 50.13% -81.92%, #999 0%, #FFF 100%);
}

.image {
  width: auto;
  height: 474px;
}

.title_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  max-width: 968px;
  font-size: 40px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: -0.4px;
  text-align: center;
  background: radial-gradient(772.83% 213.88% at 45.66% 117.9%, #7C7C7C 0%, #666 100%);
  background-clip: text;
  color: transparent;
}

.features_wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 640px) {
  .features {
    padding: 0px;
  }

  .logo_wrapper {
    padding: 40px 0 40px 0;
  }

  .image {
    height: 246px;
  }

  .title_wrapper {
    padding: 0 40px 0 40px;
    box-sizing: border-box;
  }

  .title {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.32px;
    background: radial-gradient(52.48% 330.16% at 75.02% 254.55%, #A8A8A8 0%, #666 100%);
    background-clip: text;
    color: transparent;
  }
}
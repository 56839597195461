.feature {
  position: relative;
  width: 50%;
  padding: 48px 64px 56px 64px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--Meta-Default-Neutral-000, #FFF);
  box-shadow: 0px -2px 0px 0px #E8E8E8;
}

.feature_left {
  box-shadow: -2px -2px 0px 0px #E8E8E8;
}

/* .border_top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(0deg, rgba(216, 216, 216, 0.00) -29998.36%, #727272 33848.15%);
} */

/* .border_right {
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: linear-gradient(0deg, rgba(216, 216, 216, 0.00) -29998.36%, #727272 33848.15%);
} */

.image {
  margin-bottom: 16px;
}

.title {
  font-size: 24px;
  font-weight: 400;
  line-height: 56px;
  letter-spacing: 0.24px;
  text-align: center;
  color: #666;
}

.description {
  width: 80%;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.2px;
  text-align: center;
  color: #999;
}

@media screen and (max-width: 1024px) {
  .feature {
    width: 100%;
    padding: 40px 26px 48px 26px;
  }

  .feature_left {
    box-shadow: 0px -2px 0px 0px #E8E8E8;
  }

  /* .border_right {
    display: none;
  } */

  .image {
    margin-bottom: 24px;
    width: 56px;
  }

  .title {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.2px;
    margin-bottom: 8px;
  }

  .description {
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.16px;
  }
}